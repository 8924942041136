<template>
    <div>
        <div class="card">
            <div class="card-body">
                <h5 class="mb-4">
                    <strong>Tambah Pasien Baru</strong>
                </h5>
                 <a-form :form="form" @submit="handleSubmit">
                    <a-form-item label="Nama Pasien" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-input placeholder="Nama Pasien" v-decorator="['name', { rules: [{ required: true, message: 'Nama wajib diisi!' }] }]" />
                    </a-form-item>
                    <a-form-item  label="No. RM" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-input placeholder="No. RM" v-decorator="['no_rm', { rules: [{ required: true, message: 'No. RM wajib diisi!' }] }]" />
                    </a-form-item>
                    <a-form-item label="Tanggal Lahir" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-date-picker placeholder="Tanggal Lahir" v-decorator="['birthdate' , { rules: [{ required: true, message: 'Tanggal Lahir wajib diisi!' }] }]" />
                    </a-form-item>
                    <a-form-item label="Jenis Kelamin" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-radio-group v-decorator="['gender', { initialValue: 'male' } , { rules: [{ required: true, message: 'Jenis Kelamin wajib diisi!' }] }]">
                            <a-radio value="male">Laki-laki</a-radio>
                            <a-radio value="female">Perempuan</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="Jenis Jaminan" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-select placeholder="--pilih jenis jaminan--" v-decorator="['insurance', { rules: [{ required: true, message: 'Jenis Jaminan wajib diisi!' }] }]">
                            <a-select-option value="BPJS">BPJS</a-select-option>
                            <a-select-option value="Asuransi">Asuransi</a-select-option>
                        </a-select>
                    </a-form-item>
                    <button type="submit" class="btn btn-success px-5">
                        Simpan Data
                    </button>
                 </a-form>
            </div>
        </div>
    </div>
</template>

<script>
import { create } from '@/services/axios/api/pasien'
import { notification } from 'ant-design-vue'
import router from '@/router'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}

export default {
    data: function() {
        return {
            form: this.$form.createForm(this),
            labelCol,
            wrapperCol,
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    try{
                        console.log('Received values of form: ', values)
                        const response = await create(values)
                        console.log(response)
                        if (response.status === 201){
                            notification.success({
                                message: 'Sukses',
                                description: 'Data pasien berhasil ditambah',
                            })
                            router.push('/pasien')
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            })
        },
    },
}
</script>